import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import LicenseInvalidPage from "../pages/license-invalid/LicenseInvalidPage";
import {toastError} from "../utils/ErrorHandlerUtils";
import MainButton from "../components/buttons/MainButton";
import Spinner from "../components/loading/Spinner";

interface LicenseHolder {
    validLicense?: boolean
    checkLicense: () => void
}

export const LicenseContext = createContext<LicenseHolder>({
    checkLicense: () => {},
    validLicense: true
})

export const useLicenseProvider = () => {
    const context = useContext(LicenseContext);
    if (!context) {
        throw new Error("useLicenseProvider must be used within a RequiresLicense area");
    }
    return context;
}

export interface RequiresLicenseProps {
    children: React.ReactNode
}

export const RequiresLicense = ({children}: RequiresLicenseProps) => {
    const [validLicense, setValidLicense] = useState(undefined)
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        checkLicense()
    }, []);

    const checkLicense = () => {
        setError(null)
        axios.get(getBackendURL() + "/api/v1/license/check-for-myself").then(result => {
            setValidLicense(result.data)
        }).catch(error => {
            toastError(error)
            setError(error)
        })
    }

    return (
        <LicenseContext.Provider value={{validLicense, checkLicense}}>
            {error && (
                <div>
                    <p>Failed to verify license</p>
                    <MainButton onClickEvent={checkLicense}>Retry</MainButton>
                </div>
            )}

            {validLicense === undefined && (
                <div className={"flex w-screen h-screen justify-center items-center"}>
                    <Spinner />
                </div>
            )}

            {validLicense === false && (
                <LicenseInvalidPage />
            )}

            {validLicense === true && (
                <>
                    {children}
                </>
            )}

        </LicenseContext.Provider>
    )
}