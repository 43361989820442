import React, {ReactNode} from 'react';
import {XCircleIcon} from "@heroicons/react/24/outline";

type ShoppingCartPreviewProps = {
    onQuantityChange?: (itemId: string, quantity: number) => void;
    onRemoveItem?: (itemId: string) => void;
    injectComponentsOnLine1?: ReactNode[];
    injectComponentsOnLine2?: ReactNode[];
    injectComponentsOnLine3?: ReactNode[];
    injectComponentsOnLine4?: ReactNode[];
    injectComponentsOnLine5?: ReactNode[];
    injectComponentsOnLine6?: ReactNode[];
    injectComponentsOnLine7?: ReactNode[];
    injectComponentsOnLine8?: ReactNode[];
    readonly?: boolean;
    showClose?: boolean;
    onClose?: () => void;
    totalOverride?: number;

};

const ShoppingCartPreview = (props: ShoppingCartPreviewProps) => {

    return (
        <div
            className="w-full mt-6 max-w-md bg-white p-4 rounded-lg shadow min-h-[700px] flex flex-col font-light space-y-5">
            {props.injectComponentsOnLine1?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <h1 className={"text-5xl"}>Eksempel produkt</h1>
            {props.injectComponentsOnLine2?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <div className="flex flex-row items-center text-xl space-x-4">
                <span className={"line-through text-sm text-gray-800"}>599 kr</span>
                <span className={""}>499 kr</span>
                <span className={"text-sm bg-green-500 text-white px-2 py-1 rounded-full"}>Spar 16%</span>
            </div>
            {props.injectComponentsOnLine3?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <div className={"text-sm text-gray-600"}>100% Fornøyd-garanti & Gratis frakt!</div>
            {props.injectComponentsOnLine4?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <div className="flex flex-row text-xl">
                På lager (5+) - Sendes samme dag
            </div>
            {props.injectComponentsOnLine5?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <div className={"flex flex-col text-xl"}>
                <h4 className={"text-sm"}>Color</h4>
                <div className={"flex flex-row mt-2 gap-x-2"}>
                    <div className={"border-[1px] border-gray-800 px-4 py-[1px] rounded-full bg-black text-white"}>
                        Svart
                    </div>
                    <div className={"border-[1px] border-gray-800 px-4 py-[1px] rounded-full"}>
                        Rosa
                    </div>
                    <div className={"border-[1px] border-gray-800 px-4 py-[1px] rounded-full"}>
                        Hvit
                    </div>
                </div>
            </div>
            {props.injectComponentsOnLine6?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <div className={"flex flex-col text-xl"}>
                <h4 className={"text-sm"}>Size</h4>
                <div className={"flex flex-row mt-2 gap-x-2"}>
                    <div className={"border-[1px] border-gray-800 px-4 py-[1px] rounded-full bg-black text-white"}>
                        650 ML
                    </div>
                </div>
            </div>
            {props.injectComponentsOnLine7?.map(injectedComponent => (
                <>
                    {injectedComponent}
                </>
            ))}

            <div id={"previewAddToCartButton"} className={"w-full text-center bg-yellow-300 my-2 py-3 rounded-lg font-bold shadow-xl"}>Legg til i
                handlekurv
            </div>
            {props.injectComponentsOnLine8?.map((injectedComponent, index) => (
                <React.Fragment key={`line1-${index}`}>
                    {injectedComponent}
                </React.Fragment>
            ))}
        </div>
    );
};

export default ShoppingCartPreview;