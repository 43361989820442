import React, { ReactElement } from 'react';
import Spinner from "../loading/Spinner";

interface SecondaryButtonProps {
    onClickEvent?: () => void
    children?: any
    showProcessingAnimation?: boolean
    className?: string
    icon?: ReactElement<any, any>
    doNotSubmit?: boolean
    disabled?: boolean
    disabledText?: string
}

const SecondaryButton = (props: SecondaryButtonProps) => {
    let ClonedElementWithMoreProps = null;
    if (props.icon) {
        ClonedElementWithMoreProps = React.cloneElement(
            props.icon!,
            { style: { color: "#FFFFFF" } }
        );
    }

    const baseStyles = "flex justify-center rounded-full px-6 py-2 text-md font-semibold shadow-sm";
    const enabledStyles = "bg-indigo-50 text-main-color hover:bg-indigo-200";
    const disabledStyles = "bg-gray-100 text-gray-400 cursor-not-allowed";

    return (
        <button
            type={props.doNotSubmit ? "button" : undefined}
            className={`${baseStyles} ${props.disabled ? disabledStyles : enabledStyles} ${props.showProcessingAnimation ? 'opacity-50 cursor-not-allowed' : ''} ${props.className}`}
            disabled={props.showProcessingAnimation || props.disabled}
            onClick={props.onClickEvent ? props.onClickEvent : undefined}
            title={props.disabled ? props.disabledText : undefined}
        >
            {props.icon} {props.icon ? <>&nbsp;&nbsp;</> : ""}
            {props.showProcessingAnimation ? (
                <Spinner scale={25} />
            ) : (
                props.children
            )}
        </button>
    );
};

export default SecondaryButton;