// Square Checkbox Component
import {BaseToggleProps} from "../../power-ups/shopping-cart-upsell/types/ToggleProps";
import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import ErrorText from "../input-fields/ErrorText";

export const SquareCheckbox: React.FC<BaseToggleProps> = ({
                                                              name,
                                                              onClickEvent,
                                                              isCheckedByDefault,
                                                              className,
                                                              validation,
                                                              switchColor = '#4F46E5'
                                                          }) => {
    const { register, formState: { errors } } = useFormContext();
    const [checked, setChecked] = useState<boolean>(false);
    const { ref, onChange, ...rest } = register(name, validation);

    useEffect(() => {
        setChecked(isCheckedByDefault ?? false);
    }, [isCheckedByDefault]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
        setChecked(!checked);
        if (onClickEvent) {
            onClickEvent();
        }
    };

    return (
        <div className="inline-flex items-center justify-center cursor-pointer">
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                ref={ref}
                {...rest}
                className="w-4 h-4 rounded-sm cursor-pointer"
                style={{
                    accentColor: switchColor,
                    borderColor: switchColor
                }}
            />
            <ErrorText errors={errors} name={name} />
        </div>
    );
};
