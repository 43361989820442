import React, {useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import {Product} from '../../../types/Product';
import CheckBoxComponent from "../../../components/checkboxes/CheckBoxComponent";
import MainButton from "../../../components/buttons/MainButton";
import {Card} from "@mui/material";
import {Collection} from "../../../types/Collection";
import {CheckCircleIcon} from '@heroicons/react/24/outline';
import {OneTickUpsellRuleType} from "../types/OneTickUpsellRuleType";
import InputSearchField from "../../../components/input-fields/InputSearchField";
import SearchInput from "../../../components/input-fields/InputSearchField";
import {validateProductInventory} from "../validation/ValidateUpsellForm";
import {toast} from "react-hot-toast";

interface ProductSelectionProps {
    productList: Product[];
    collectionList?: Collection[];
    onProductsSelected: (products: Product[]) => void;
    onCollectionsSelected?: (collections: Collection[]) => void;
    selectedProducts: Product[];
    selectedCollections?: Collection[];
    selectedRuleType: OneTickUpsellRuleType;
    onRuleTypeChange: (type: OneTickUpsellRuleType) => void;
}

const ProductSelection: React.FC<ProductSelectionProps> = ({
                                                               productList,
                                                               collectionList = [],
                                                               onProductsSelected,
                                                               onCollectionsSelected,
                                                               selectedProducts: initialSelectedProducts,
                                                               selectedCollections: initialSelectedCollections = [],
                                                               selectedRuleType,
                                                               onRuleTypeChange
                                                           }) => {
    const [productSearchTerm, setProductSearchTerm] = useState('');
    const [collectionSearchTerm, setCollectionSearchTerm] = useState('');
    const [localSelectedProducts, setLocalSelectedProducts] = useState<Product[]>(initialSelectedProducts);
    const [localSelectedCollections, setLocalSelectedCollections] = useState<Collection[]>(initialSelectedCollections);
    const [isProductsConfirmed, setIsProductsConfirmed] = useState(false);
    const [isCollectionsConfirmed, setIsCollectionsConfirmed] = useState(false);
    const [isAllProductsConfirmed, setIsAllProductsConfirmed] = useState(true);
    const [showProductList, setShowProductList] = useState(false);
    const [showCollectionList, setShowCollectionList] = useState(false);
    const [activeRule, setActiveRule] = useState<OneTickUpsellRuleType>(selectedRuleType);


    useEffect(() => {
        if (selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS) {
            if (initialSelectedCollections?.length > 0) {
                setLocalSelectedCollections(initialSelectedCollections);
                setIsCollectionsConfirmed(true);
            } else {
                setLocalSelectedCollections([]);
                setIsCollectionsConfirmed(false);
            }
        }
    }, [selectedRuleType, initialSelectedCollections]);

    // Legg til denne useEffect sammen med den eksisterende useEffect for collections
    useEffect(() => {
        if (selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS) {
            if (initialSelectedProducts?.length > 0) {
                setLocalSelectedProducts(initialSelectedProducts);
                setIsProductsConfirmed(true);
            } else {
                setLocalSelectedProducts([]);
                setIsProductsConfirmed(false);
            }
        }
    }, [selectedRuleType, initialSelectedProducts]);

    const isProductChecked = (product: Product) => {
        return localSelectedProducts.some(p => p.graphqlId === product.graphqlId);
    };

    const handleSelectionTypeChange = (type: 'all' | 'specific' | 'collections') => {
        let newRuleType: OneTickUpsellRuleType;
        switch (type) {
            case 'all':
                newRuleType = OneTickUpsellRuleType.ALL_PRODUCTS;
                break;
            case 'specific':
                newRuleType = OneTickUpsellRuleType.SPECIFIC_PRODUCTS;
                break;
            case 'collections':
                newRuleType = OneTickUpsellRuleType.SPECIFIC_COLLECTIONS;
                break;
        }

        if (newRuleType === selectedRuleType) return;

        // Lukk alle åpne lister
        setShowProductList(false);
        setShowCollectionList(false);

        // Oppdater aktiv regel
        setActiveRule(newRuleType);
        onRuleTypeChange(newRuleType);

        // Tilbakestill confirmed state for gamle regler
        if (newRuleType === OneTickUpsellRuleType.ALL_PRODUCTS) {
            setIsProductsConfirmed(false);
            setIsCollectionsConfirmed(false);
            setIsAllProductsConfirmed(true);
        } else if (newRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS) {
            setIsAllProductsConfirmed(false);
            setIsCollectionsConfirmed(false);
        } else if (newRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS) {
            setIsAllProductsConfirmed(false);
            setIsProductsConfirmed(false);
        }
    };

    const handleProductSelect = (product: Product) => {
        // Sjekk om produktet er på lager før det kan velges
        if (!validateProductInventory(product)) {
            toast.error(`${product.title} er utsolgt, og kan derfor ikke velges`);
            return;
        }

        const isSelected = localSelectedProducts.some(p => p.graphqlId === product.graphqlId);
        const newSelection = isSelected
            ? localSelectedProducts.filter(p => p.graphqlId !== product.graphqlId)
            : [...localSelectedProducts, product];

        setLocalSelectedProducts(newSelection);
        onProductsSelected(newSelection);
    };

    const handleCollectionSelect = (collection: Collection) => {
        const isSelected = localSelectedCollections.some(c => c.graphqlId === collection.graphqlId);
        const newSelection = isSelected
            ? localSelectedCollections.filter(c => c.graphqlId !== collection.graphqlId)
            : [...localSelectedCollections, collection];

        setLocalSelectedCollections(newSelection);
        setIsCollectionsConfirmed(newSelection.length > 0);

        if (onCollectionsSelected) {
            onCollectionsSelected(newSelection);
        }
    };

    const handleSaveProducts = () => {
        onProductsSelected(localSelectedProducts);
        setIsProductsConfirmed(true);
        setShowProductList(false);

        // Tilbakestill andre regler
        setIsAllProductsConfirmed(false);
        setIsCollectionsConfirmed(false);
        setLocalSelectedCollections([]); // Tøm valgte kolleksjoner
        if (onCollectionsSelected) {
            onCollectionsSelected([]); // Informer parent om at kolleksjoner er tømt
        }
    };

    const handleSaveCollections = () => {
        if (onCollectionsSelected) {
            onCollectionsSelected(localSelectedCollections);
        }
        setIsCollectionsConfirmed(true);
        setShowCollectionList(false);

        // Tilbakestill andre regler
        setIsAllProductsConfirmed(false);
        setIsProductsConfirmed(false);
        setLocalSelectedProducts([]); // Tøm valgte produkter
        onProductsSelected([]); // Informer parent om at produkter er tømt
    };

    const filteredProducts = productList.filter(product =>
        product.title.toLowerCase().includes(productSearchTerm.toLowerCase()) &&
        validateProductInventory(product)
    );

    const filteredCollections = collectionList.filter(collection =>
        collection.title.toLowerCase().includes(collectionSearchTerm.toLowerCase())
    );

    return (
        <motion.div
            initial={{x: 100, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            exit={{x: -100, opacity: 0}}
            className="space-y-4"
        >
            <div>
                <h2 className="text-2xl font-semibold mb-2 mt-4">Velg produkter for denne powerupen</h2>
                <p className="text-gray-600 mb-6">
                    Denne kampanjen vil vises på produktene du velger nedenfor.
                </p>
            </div>

            <div className="flex gap-4 items-center justify-between">
                <Card
                    className={`p-4 cursor-pointer transition-all ${
                        selectedRuleType === OneTickUpsellRuleType.ALL_PRODUCTS
                            ? 'border-2 border-main-color bg-main-color/10'
                            : 'hover:bg-gray-50'
                    }`}
                    onClick={() => handleSelectionTypeChange('all')}
                >
                    <div className="flex items-center gap-2">
        <span className={`font-medium ${
            selectedRuleType === OneTickUpsellRuleType.ALL_PRODUCTS && isAllProductsConfirmed
                ? 'text-main-color'
                : ''
        }`}>
            Alle produkter
        </span>
                        {selectedRuleType === OneTickUpsellRuleType.ALL_PRODUCTS && isAllProductsConfirmed &&
                            <CheckCircleIcon className="w-5 h-5 text-main-color"/>}
                    </div>
                </Card>

                <Card
                    className={`p-4 cursor-pointer transition-all ${
                        selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS
                            ? 'border-2 border-main-color bg-main-color/10'
                            : 'hover:bg-gray-50'
                    }`}
                    onClick={() => handleSelectionTypeChange('specific')}
                >
                    <div className="flex items-center gap-2">
        <span className={`font-medium ${
            selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS && isProductsConfirmed
                ? 'text-main-color'
                : ''
        }`}>
            Spesifikke produkter
        </span>
                        {selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS && isProductsConfirmed &&
                            <CheckCircleIcon className="w-5 h-5 text-main-color"/>}
                    </div>
                </Card>

                <Card
                    className={`p-4 cursor-pointer transition-all ${
                        selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS
                            ? 'border-2 border-main-color bg-main-color/10'
                            : 'hover:bg-gray-50'
                    }`}
                    onClick={() => handleSelectionTypeChange('collections')}
                >
                    <div className="flex items-center gap-2">
        <span className={`font-medium ${
            selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS && isCollectionsConfirmed
                ? 'text-main-color'
                : ''
        }`}>
            Spesifikke kolleksjoner
        </span>
                        {selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS && isCollectionsConfirmed &&
                            <CheckCircleIcon className="w-5 h-5 text-main-color"/>}
                    </div>
                </Card>
            </div>

            {selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS && !showProductList && (
                <div className="mt-4">
                    <h3 className="text-lg font-medium mb-2">
                        Spesifiser produktene
                        {localSelectedProducts.length > 0 &&
                            ` (${localSelectedProducts.length} valgt)`
                        }
                    </h3>
                    <MainButton
                        onClickEvent={() => setShowProductList(true)}
                        className="w-full"
                    >
                        {localSelectedProducts.length > 0 ? 'Endre produktvalg' : 'Velg produkter'}
                    </MainButton>
                </div>
            )}

            {selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS && showProductList && (
                <motion.div
                    initial={{x: 100, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    exit={{x: -100, opacity: 0}}
                    className="flex flex-col h-full"
                >
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Velg produkter</h2>
                        <span className="text-sm text-gray-500">
                            {localSelectedProducts.length} produkter valgt
                        </span>
                    </div>

                    <SearchInput
                        value={productSearchTerm}
                        onChange={(e) => setProductSearchTerm(e.target.value)}
                        placeholder="Søk etter produkter..."
                    />

                    <div className="flex-grow max-h-96 overflow-y-auto space-y-2 my-4">
                        {filteredProducts.length === 0 ? (
                            <div className="text-center text-gray-500 py-4">
                                Ingen produkter funnet
                            </div>
                        ) : (
                            filteredProducts.map(product => (
                                <div
                                    key={product.id}
                                    className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                                    onClick={() => handleProductSelect(product)}
                                >
                                    <CheckBoxComponent
                                        onClickEvent={() => handleProductSelect(product)}
                                        name={`product-${product.id}`}
                                        isCheckedByDefault={isProductChecked(product)}
                                    />
                                    <span>{product.title}</span>
                                </div>
                            ))
                        )}
                    </div>

                    <MainButton
                        onClickEvent={handleSaveProducts}
                        className="w-full"
                    >
                        Lagre produkter
                    </MainButton>
                </motion.div>
            )}

            {selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS && !showCollectionList && (
                <div className="mt-4">
                    <h3 className="text-lg font-medium mb-2">
                        Spesifiser kolleksjoner
                        {localSelectedCollections.length > 0 &&
                            ` (${localSelectedCollections.length} valgt)`
                        }
                    </h3>
                    <MainButton
                        onClickEvent={() => setShowCollectionList(true)}
                        className="w-full"
                    >
                        {localSelectedCollections.length > 0 ? 'Endre kolleksjoner' : 'Velg kolleksjoner'}
                    </MainButton>
                </div>
            )}

            {selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS && showCollectionList && (
                <motion.div
                    initial={{x: 100, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    exit={{x: -100, opacity: 0}}
                    className="flex flex-col h-full"
                >
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Velg kolleksjoner</h2>
                        <span className="text-sm text-gray-500">
                            {localSelectedCollections.length} kolleksjoner valgt
                        </span>
                    </div>

                    <SearchInput
                        value={collectionSearchTerm}
                        onChange={(e) => setCollectionSearchTerm(e.target.value)}
                        placeholder="Søk etter kolleksjoner..."
                        className="mb-4"
                    />

                    <div className="flex-grow max-h-96 overflow-y-auto space-y-2 my-4">
                        {filteredCollections.length === 0 ? (
                            <div className="text-center text-gray-500 py-4">
                                Ingen kolleksjoner funnet
                            </div>
                        ) : (
                            filteredCollections.map(collection => {
                                const isChecked = localSelectedCollections.some(c => c.graphqlId === collection.graphqlId);

                                return (
                                    <div key={`${collection.id}-${isChecked}`} className="space-y-2">
                                        <div
                                            className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                                            onClick={() => handleCollectionSelect(collection)}
                                        >
                                            <CheckBoxComponent
                                                onClickEvent={() => handleCollectionSelect(collection)}
                                                name={`collection-${collection.id}`}
                                                isCheckedByDefault={isChecked}
                                            />
                                            <span>{collection.title}</span>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>

                    <MainButton
                        onClickEvent={handleSaveCollections}
                        className="w-full"
                    >
                        Lagre kolleksjoner
                    </MainButton>
                </motion.div>
            )}
        </motion.div>
    );
};

export default ProductSelection;