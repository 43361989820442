import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface InputSearchFieldProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
}

const InputSearchField = ({
                              value,
                              onChange,
                              placeholder,
                              className
                          }: InputSearchFieldProps) => {
    return (
        <TextField
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={className}
            variant="outlined"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
                    </InputAdornment>
                ),
                endAdornment: value ? (
                    <InputAdornment position="end">
                        <button
                            type="button"
                            onClick={() => onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
                            className="p-1 hover:bg-gray-100 rounded-full"
                            aria-label="Clear search"
                        >
                            <XMarkIcon className="h-5 w-5 text-gray-500" />
                        </button>
                    </InputAdornment>
                ) : null
            }}
        />
    );
};

export default InputSearchField;