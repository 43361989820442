import React, { useEffect, useState } from "react";
import TextAreaField from '../../../components/input-fields/TextAreaField';
import { Switch } from "@mui/material";

interface ShoppingCartUpsellTextSettingsProps {
    tickedByDefault: boolean;
    upsellText: string;
    onTickedByDefaultChange: (value: boolean) => void;
    onUpsellTextChange: (text: string) => void;
    productId: string;
}

const ShoppingCartUpsellTextSettings: React.FC<ShoppingCartUpsellTextSettingsProps> = ({
                                                                                           tickedByDefault,
                                                                                           upsellText,
                                                                                           onTickedByDefaultChange,
                                                                                           onUpsellTextChange,
                                                                                           productId
                                                                                       }) => {
    const TEMPLATE_TITTEL = "{{tittel}}";
    const TEMPLATE_PRIS = "{{pris}}";

    // Lokal state for å håndtere tekstfeltets verdi
    const [localText, setLocalText] = useState(upsellText);

    // Oppdater lokal state når props endres
    useEffect(() => {
        setLocalText(upsellText);
    }, [upsellText, productId]);

    // Håndter tekstendringer
    const handleTextChange = (newText: string) => {
        setLocalText(newText);
        onUpsellTextChange(newText);
    };

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <div>
                        <h3 className="text-lg font-medium">Forhåndsvalgt</h3>
                        <p className="text-gray-600">
                            Når powerupen er aktiv, vil bryteren automatisk være på, og produktet vil bli lagt til i kundens handlekurv.
                        </p>
                    </div>
                    <Switch
                        checked={tickedByDefault}
                        aria-label="Toggle default selection"
                        onChange={(e) => onTickedByDefaultChange(e.target.checked)}
                        color="primary"
                    />
                </div>
            </div>

            <div className="space-y-2">
                <h3 className="text-lg font-medium">Tilpass tekst</h3>
                <div className="mt-2 p-3 bg-blue-50 border border-blue-200 rounded-md flex items-start gap-2">
                    <p className="text-sm text-gray-600">
                        Teksten må inneholde både <span
                        className="font-mono bg-gray-100 px-1 rounded">{TEMPLATE_TITTEL}</span> og <span
                        className="font-mono bg-gray-100 px-1 rounded">{TEMPLATE_PRIS}</span> for å være gyldig.
                    </p>
                </div>
                <TextAreaField
                    name={`upsellText-${productId}`}
                    label="Tekst"
                    value={localText}
                    onChange={handleTextChange}
                    validation={{
                        required: {value: true, message: "Tekst er påkrevd"},
                        validate: (value: string) => {
                            if (!value.includes(TEMPLATE_TITTEL) || !value.includes(TEMPLATE_PRIS)) {
                                return "Teksten må inneholde både {{tittel}} og {{pris}}";
                            }
                            return true;
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ShoppingCartUpsellTextSettings;