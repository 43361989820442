import React from 'react';
import {XCircleIcon} from "@heroicons/react/24/outline";


export type CartItem = {
    id: string;
    name: string;
    price: number;
    quantity: number;
    image: string;
    targetGender?: string;
};

export type CartAction = {
    id: string;
    component: React.ReactNode;
    position: 'before-total' | 'after-total';
};

type ShoppingCartPreviewProps = {
    items: CartItem[];
    onQuantityChange?: (itemId: string, quantity: number) => void;
    onRemoveItem?: (itemId: string) => void;
    actions?: CartAction[];
    readonly?: boolean;
    showClose?: boolean;
    onClose?: () => void;
    totalOverride?: number;

};

const ShoppingCartPreview = ({
                                 items,
                                 onQuantityChange,
                                 onRemoveItem,
                                 actions = [],
                                 readonly = false,
                                 showClose = true,
                                 onClose,
                                 totalOverride,
                             }: ShoppingCartPreviewProps) => {
    const calculateSubtotal = () => {
        if (totalOverride !== undefined) {
            return totalOverride;
        }
        return items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    };

    return (
        <div className="w-full mt-6 max-w-md bg-white p-4 rounded-lg shadow min-h-[700px] flex flex-col">
            {/* Header */}
            <div className="flex justify-between items-center border-b pb-4">
                <h2 className="text-xl font-semibold"> Din handlekurv</h2>
            </div>

            {/* Product list with scrolling */}
            <div className="flex-1 overflow-y-auto py-4">
                {items.map((item) => (
                    <div key={item.id} className="flex items-start gap-4 mb-4">
                        <div className="flex-1">
                            <h3 className="font-medium">{item.name}</h3>
                            <p className="text-sm text-gray-600">{item.price.toFixed(2)} kr</p>
                            {item.targetGender && (
                                <p className="text-sm text-gray-500">Målgruppe: {item.targetGender}</p>
                            )}
                            <div className="flex items-center mt-2 space-x-2">
                                <button
                                    onClick={() => onQuantityChange?.(item.id, Math.max(0, item.quantity - 1))}
                                    className="w-8 h-8 flex items-center justify-center border rounded"
                                >
                                    -
                                </button>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => onQuantityChange?.(item.id, parseInt(e.target.value) || 0)}
                                    className="w-16 text-center border rounded p-1"
                                    min="0"
                                />
                                <button
                                    onClick={() => onQuantityChange?.(item.id, item.quantity + 1)}
                                    className="w-8 h-8 flex items-center justify-center border rounded"
                                >
                                    +
                                </button>
                                <button
                                    onClick={() => onRemoveItem?.(item.id)}
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    <XCircleIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>



            {/* Footer info */}
            <div className="mt-auto space-y-2 border-t pt-4">
                <p className="text-sm">100% Fornøyd-garanti</p>
                <p className="text-sm">Gratis frakt i hele Norge</p>
                <p className="text-sm">30 Dagers Åpent kjøp</p>
            </div>

            {/* Total and checkout */}
            <div className="border-t mt-4 pt-4">
                <div className="flex items-center justify-between mb-4">
                    <span>Sum</span>
                    <span className="font-medium">{calculateSubtotal().toFixed(2)} NOK</span>
                </div>
                {/* Custom actions (upsells) */}
                {actions?.map(action => (
                    <div key={action.id} className="py-2">
                        {action.component}
                    </div>
                ))}

                <button
                    className="w-full bg-yellow-300 hover:bg-yellow-400 text-black py-3 rounded-lg font-medium transition-colors">
                    Til utsjekk
                </button>

                <p className="text-xs text-gray-500 mt-2 text-center">
                    Moms inkludert. Frakt og rabatter beregnes i kassen
                </p>
            </div>
        </div>
    );
};

export default ShoppingCartPreview;