import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
`;

export const ColorDisplay = styled.div<{ $color: string }>`
  width: 36px;
  height: 36px;
  border-radius: 2px;
  background: ${props => props.$color};
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 9999;
  margin-top: 2px;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const PickerContainer = styled.div`
  position: relative;
  z-index: 9999;
`;

export const Label = styled.span`
  color: #4B5563; // text-gray-600 equivalent
`;