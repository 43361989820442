import {FormProvider, useForm} from "react-hook-form";
import ColorPicker from "../../components/input-fields/ColorPicker";
import React, {useEffect, useState} from "react";
import InputTextField from "../../components/input-fields/InputTextField";
import {PaymentIcon} from "./types/PaymentIcon";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";
import {PaymentIconSettings} from "./types/PaymentIconSettings";
import {CircleRadio} from "../../components/checkboxes/CircleRadioImproved";
import {PaymentIconPlacement} from "./enums/PaymentIconPlacement";
import {PaymentIconSize} from "./enums/PaymentIconSize";
import {ToggleSwitch} from "../../components/checkboxes/ToggleSwitchImproved";
import DestructiveButton from "../../components/buttons/DestructiveButton";
import MainButton from "../../components/buttons/MainButton";
import {usePowerUpsProvider} from "../../providers/PowerUpsProvider";
import toast from "react-hot-toast";
import PaymentIconsProductMetaPreview from "./components/PaymentIconsProductMetaPreview";
import Heading2 from "../../components/text/Heading2";

export const PaymentIconsForm = () => {
    const {disablePowerUp, closeCustomizeMenu} = usePowerUpsProvider()

    const methods = useForm()

    const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false)

    const [selectedPaymentIcons, setSelectedPaymentIcons] = useState<PaymentIcon[] | undefined>()
    const [availablePaymentIcons, setAvailablePaymentIcons] = useState<PaymentIcon[] | undefined>()

    const [paymentIconSettings, setPaymentIconSettings] = useState<PaymentIconSettings | null | undefined>(undefined)

    useEffect(() => {
        loadAvailablePaymentIcons();
    }, []);

    useEffect(() => {
        if (availablePaymentIcons == undefined) {
            return
        }
        loadSettings()
    }, [availablePaymentIcons]);

    const loadAvailablePaymentIcons = () => {
        axios.get(getBackendURL() + "/api/v1/power-ups/payment-icons/icons").then(result => {
            setAvailablePaymentIcons(result.data);
        }).catch(error => {
            toastError(error);
        })
    }

    const loadSettings = () => {
        axios.get(getBackendURL() + "/api/v1/power-ups/payment-icons/settings").then(result => {
            if (!result.data) {
                setPaymentIconSettings(null);
                setSelectedPaymentIcons([]);
                return;
            }

            let paymentIconSettings = result.data as PaymentIconSettings;
            setPaymentIconSettings(paymentIconSettings);

            if (paymentIconSettings === undefined || paymentIconSettings.paymentIcons.length === 0) {
                setSelectedPaymentIcons([])
                return
            } else {
                let paymentIconIds = paymentIconSettings.paymentIcons.split(",")
                let selectedIcons = availablePaymentIcons?.filter(icon => paymentIconIds.includes(icon.id))
                setSelectedPaymentIcons(selectedIcons)
            }
        }).catch((error) => {
            toastError(error)
        }).finally(() => {
            setSaveButtonEnabled(true)
        })
    }

    const getIconStyles = (icon: PaymentIcon) => {
        if (selectedPaymentIcons?.includes(icon)) {
            return "opacity-100 border-2 border-main-color"
        } else {
            return "opacity-30 hover:opacity-100"
        }
    }

    const onPaymentIconSelected = (paymentIcon: PaymentIcon) => {
        if (!selectedPaymentIcons?.includes(paymentIcon)) {
            let newPaymentIcons = [paymentIcon, ...selectedPaymentIcons!]
            setPaymentIconSettings({...paymentIconSettings!, paymentIcons: newPaymentIcons.map(icon => icon.id).join(",")})
            setSelectedPaymentIcons(newPaymentIcons)
        } else {
            let newPaymentIcons = selectedPaymentIcons?.filter(icon => icon.id !== paymentIcon.id);
            setPaymentIconSettings({...paymentIconSettings!, paymentIcons: newPaymentIcons.map(icon => icon.id).join(",")})
            setSelectedPaymentIcons(newPaymentIcons)
        }
    }

    const colorDidChange = (color: string) => {
        setPaymentIconSettings({...paymentIconSettings!, backgroundColor: color})
    }

    const handleSubmit = () => {
        setSaveButtonEnabled(false)
        axios.post(getBackendURL() + "/api/v1/power-ups/payment-icons/settings", {
            backgroundColor: paymentIconSettings?.backgroundColor,
            headerText: methods.getValues("description"),
            placement: methods.getValues("paymentIconPlacement"),
            size: methods.getValues("paymentIconSize"),
            paymentIconIds: selectedPaymentIcons?.map(icon => icon.id),
            displayOnMobile: methods.getValues("displayOnMobile")
        }).then(result => {
            toast.success("Lagret")
            closeCustomizeMenu && closeCustomizeMenu()
        }).catch(error => {
            toastError(error);
        }).finally(() => {
            setSaveButtonEnabled(true)
        })
    }

    return (
        <>
            {availablePaymentIcons !== undefined && selectedPaymentIcons !== undefined && paymentIconSettings !== undefined && (
                <div className={"grid grid-cols-2 py-5"}>
                    <div>
                        <FormProvider {...methods}>
                            <form className={"space-y-5"} onSubmit={methods.handleSubmit(handleSubmit)}>
                                <div className="mb-6">
                                    <Heading2 className={"mt-4"}>Tilpass oppsettet</Heading2>
                                    <p className="text-gray-600 mb-6">
                                        Velg hvordan du ønsker at Power-upen skal se ut på produktsiden.
                                    </p>

                                </div>

                                <div className={"space-y-2"}>
                                    <strong>Design</strong>
                                    <br/>
                                    <ColorPicker
                                        color={paymentIconSettings?.backgroundColor || "#FFFFFF"}
                                        onChange={colorDidChange}
                                        label={"Bakgrunnsfarge (Valgfritt)"}
                                    />
                                </div>

                                <div className={"space-y-2"}>
                                    <strong>Tekst</strong>
                                    <p>Beskrivelse (Valgfritt)</p>
                                    <br/>
                                    <InputTextField
                                        name={"description"}
                                        type={"text"}
                                        label={"Beskrivelse"}
                                        value={paymentIconSettings?.headerText || "Betal trygt med:"}
                                        onChange={(value) => setPaymentIconSettings({...paymentIconSettings!, headerText: value})}
                                    />
                                </div>

                                <div className={"space-y-2"}>
                                    <strong>Ikoner</strong>
                                    <p>Velg ikonene du ønsker å vise</p>
                                    <div className={"grid grid-cols-5 gap-y-4 gap-x-2 w-72"}>
                                        {availablePaymentIcons?.map(icon => (
                                            <div
                                                id={"payment-icon-" + icon.id}
                                                onClick={() => onPaymentIconSelected(icon)} key={icon.id}
                                                className={getIconStyles(icon) + " rounded-md transition-all cursor-pointer"}
                                            >
                                                <img className={"w-16"} src={icon.iconUrl}
                                                     alt={`${icon.name} payment icon`}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className={"space-y-2"}>
                                    <strong>Plassering</strong>
                                    <div>
                                        <CircleRadio
                                            className={"flex-col"}
                                            name={"paymentIconPlacement"}
                                            defaultValue={paymentIconSettings?.placement || PaymentIconPlacement.ABOVE_PURCHASE_BUTTON}
                                            onChange={(value) => setPaymentIconSettings({...paymentIconSettings!, placement: PaymentIconPlacement[value as keyof typeof PaymentIconPlacement]})}
                                            options={[
                                                {
                                                    label: "Over handlekurv-knapp",
                                                    value: PaymentIconPlacement.ABOVE_PURCHASE_BUTTON.valueOf()
                                                },
                                                {
                                                    label: "Under handlekurv-knapp",
                                                    value: PaymentIconPlacement.BELOW_PURCHASE_BUTTON.valueOf()
                                                },
                                            ]}/>
                                    </div>
                                </div>

                                <div className={"space-y-2"}>
                                    <strong>Ikon størrelse</strong>
                                    <div>
                                        <CircleRadio
                                            className={"flex-col"}
                                            name={"paymentIconSize"}
                                            onChange={(value) => setPaymentIconSettings({...paymentIconSettings!, size: PaymentIconSize[value as keyof typeof PaymentIconSize]})}
                                            defaultValue={paymentIconSettings?.size || PaymentIconSize.MEDIUM}
                                            options={[
                                                {
                                                    label: "Small",
                                                    value: PaymentIconSize.SMALL.valueOf()
                                                },
                                                {
                                                    label: "Medium",
                                                    value: PaymentIconSize.MEDIUM.valueOf()
                                                },
                                                {
                                                    label: "Large",
                                                    value: PaymentIconSize.LARGE.valueOf()
                                                },
                                            ]}/>
                                    </div>
                                </div>

                                <div className={"flex items-center gap-x-2"}>
                                    <ToggleSwitch
                                        name={"displayOnMobile"}
                                        onChange={(value) => setPaymentIconSettings({...paymentIconSettings!, displayOnMobile: value})}
                                        defaultValue={paymentIconSettings?.displayOnMobile != undefined ? paymentIconSettings?.displayOnMobile : true}
                                    />
                                    Vis på mobil
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                    <div className={"border-l border-gray-200 px-6"}>
                        <PaymentIconsProductMetaPreview
                            paymentIconSettings={paymentIconSettings!}
                            availablePaymentIcons={availablePaymentIcons}
                            />
                    </div>
                </div>
            )}
            <div className={"flex justify-center space-x-4"}>
                <DestructiveButton onClickEvent={disablePowerUp}>
                    Deaktiver
                </DestructiveButton>
                <MainButton
                    onClickEvent={handleSubmit}
                    showProcessingAnimation={!saveButtonEnabled}
                    disabled={!saveButtonEnabled}
                >
                    Lagre
                </MainButton>
            </div>
        </>
    )
}

export default PaymentIconsForm