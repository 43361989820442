// validateUpsellForm.ts
import { Product } from "../../../types/Product";
import { Collection } from "../../../types/Collection";
import { OneTickUpsellRuleType } from "../types/OneTickUpsellRuleType";
import { UpsellProduct } from "../types/UpsellProduct";
import { ProductVariant } from "../../../types/ProductVariant";

type UpsellFormData = {
    ruleType: OneTickUpsellRuleType | null;
    selectedProducts: Product[];
    selectedCollections: Collection[];
    upsellProducts: UpsellProduct[];
};

enum ValidationField {
    RULE_TYPE = 'RULE_TYPE',
    PRODUCT_SELECTION = 'PRODUCT_SELECTION',
    COLLECTION_SELECTION = 'COLLECTION_SELECTION',
    UPSELL_PRODUCTS = 'UPSELL_PRODUCTS',
    INVENTORY = 'INVENTORY',
    DUPLICATE_PRODUCTS = 'DUPLICATE_PRODUCTS'  // Ny valideringstype
}

const validateVariantInventory = (variant: ProductVariant | undefined): boolean => {
    if (!variant) return false;
    return typeof variant.inventoryQuantity === 'number' && variant.inventoryQuantity > 0;
};

const hasValidInventory = (product: Product | undefined | null): boolean => {
    if (!product?.variants?.length) {
        return false;
    }
    return product.variants.some(validateVariantInventory);
};

const hasDuplicateProducts = (upsellProducts: UpsellProduct[]): string[] => {
    const productIds = new Map<string, string>();
    const duplicates: string[] = [];

    upsellProducts.forEach((up, index) => {
        if (up.product) {
            const productId = up.product.id;
            if (productIds.has(productId)) {
                duplicates.push(`"${up.product.title}" er allerede valgt som upsell-produkt`);
            } else {
                productIds.set(productId, up.product.title);
            }
        }
    });

    return duplicates;
};

const validateField = (field: ValidationField, data: UpsellFormData): string | null => {
    try {
        switch (field) {
            case ValidationField.RULE_TYPE:
                return !data.ruleType
                    ? "Regeltype er påkrevd"
                    : null;

            case ValidationField.PRODUCT_SELECTION:
                return data.ruleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS &&
                (!data.selectedProducts?.length)
                    ? "Vennligst velg minst ett produkt"
                    : null;

            case ValidationField.COLLECTION_SELECTION:
                return data.ruleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS &&
                (!data.selectedCollections?.length)
                    ? "Vennligst velg minst én kolleksjon"
                    : null;

            case ValidationField.UPSELL_PRODUCTS:
                if (!data.upsellProducts?.length) {
                    return "Ingen upsell-produkter definert";
                }
                return data.upsellProducts.some(up => !up?.product)
                    ? "Vennligst velg produkter for alle upsells"
                    : null;

            case ValidationField.DUPLICATE_PRODUCTS:
                const duplicates = hasDuplicateProducts(data.upsellProducts || []);
                return duplicates.length > 0 ? duplicates.join('\n') : null;

            case ValidationField.INVENTORY:
                const outOfStockProducts = (data.upsellProducts || [])
                    .filter(up => up?.product)
                    .filter(up => !hasValidInventory(up.product))
                    .map(up => up.product?.title || 'Ukjent produkt');

                return outOfStockProducts.length > 0
                    ? outOfStockProducts.map(title => `${title} er utsolgt, og kan derfor ikke velges`).join('\n')
                    : null;

            default:
                return null;
        }
    } catch (error) {
        console.error(`Validation error in field ${field}:`, error);
        return "Det oppstod en feil under validering";
    }
};

export const validateUpsellForm = (formData: UpsellFormData): string[] => {
    if (!formData) {
        return ["Ugyldig skjemadata"];
    }

    const validationFields = [
        ValidationField.RULE_TYPE,
        ValidationField.PRODUCT_SELECTION,
        ValidationField.COLLECTION_SELECTION,
        ValidationField.UPSELL_PRODUCTS,
        ValidationField.DUPLICATE_PRODUCTS,  // Legger til duplikat-sjekk i valideringsrekkefølgen
        ValidationField.INVENTORY
    ];

    return validationFields
        .map(field => validateField(field, formData))
        .filter((error): error is string => error !== null);
};

// Helper functions for external use
export const validateProductInventory = (product: Product | undefined | null): boolean => {
    return hasValidInventory(product);
};

export const validateSingleVariant = (variant: ProductVariant | undefined): boolean => {
    return validateVariantInventory(variant);
};