// Toggle Switch Component
import {BaseToggleProps} from "../../power-ups/shopping-cart-upsell/types/ToggleProps";
import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import ErrorText from "../input-fields/ErrorText";

export const ToggleSwitch: React.FC<BaseToggleProps> = ({
                                                            name,
                                                            onClickEvent,
                                                            isCheckedByDefault,
                                                            className,
                                                            validation,
                                                            switchColor = '#4F46E5'
                                                        }) => {
    const { register, formState: { errors } } = useFormContext();
    const [checked, setChecked] = useState<boolean>(false);
    const { ref, onChange, ...rest } = register(name, validation);

    useEffect(() => {
        setChecked(isCheckedByDefault ?? false);
    }, [isCheckedByDefault]);

    const handleChange = () => {
        const event = { target: { name, value: !checked } } as unknown as React.ChangeEvent<HTMLInputElement>;
        onChange(event);
        setChecked(!checked);
        if (onClickEvent) {
            onClickEvent();
        }
    };

    return (
        <div className="relative inline-block w-8 md:w-10 h-5 md:h-6">
            <input
                type="checkbox"
                className="hidden"
                ref={ref}
                checked={checked}
                onChange={() => {}}
                {...rest}
            />
            <div
                className="w-8 md:w-10 h-5 md:h-6 rounded-full p-1 cursor-pointer transition-colors duration-200 ease-in-out"
                style={{ backgroundColor: checked ? switchColor : '#e5e7eb' }}
                onClick={handleChange}
            >
                <div
                    className={`bg-white w-3 md:w-4 h-3 md:h-4 rounded-full shadow-md transform duration-200 ease-in-out ${
                        checked ? 'translate-x-3 md:translate-x-4' : ''
                    }`}
                />
            </div>
            <ErrorText errors={errors} name={name} />
        </div>
    );
};