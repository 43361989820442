import React, {ReactNode, useEffect, useState} from 'react';
import Heading2 from "../../../components/text/Heading2";
import {PaymentIconSettings} from "../types/PaymentIconSettings";
import ProductPageMetaPreview from "../../../components/preview/ProductPageMetaPreview";
import {PaymentIcon} from "../types/PaymentIcon";
import {PaymentIconPlacement} from "../enums/PaymentIconPlacement";
import {PaymentIconSize} from "../enums/PaymentIconSize";

type ShoppingCartPaymentIconsPreviewProps = {
    paymentIconSettings?: PaymentIconSettings;
    availablePaymentIcons: PaymentIcon[]
};

const PaymentIconsProductMetaPreview = ({
                                            paymentIconSettings,
                                            availablePaymentIcons
                                        }: ShoppingCartPaymentIconsPreviewProps) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const getImgHeight = (): string => {
        switch (paymentIconSettings?.size) {
            case PaymentIconSize.SMALL:
                return "h-6"
            case PaymentIconSize.MEDIUM:
                return "h-8"
            case PaymentIconSize.LARGE:
                return "h-10"
            default:
                return "h-8"
        }
    }

    const injectionComponents: ReactNode[] = [(
        <div className={"flex flex-col " + (paymentIconSettings?.backgroundColor ? "rounded-xl py-2" : "")}
             style={{backgroundColor: paymentIconSettings?.backgroundColor ? paymentIconSettings.backgroundColor : ""}}>
            <div className={"flex flex-row justify-center text-base"} id={"paymentIconsDescriptionPreview"}>
                {paymentIconSettings?.headerText || "Betal trygt med:"}
            </div>
            <div id={"paymentIconsPreview"} className={"inline mt-2 text-center space-x-2"}>
                {paymentIconSettings && paymentIconSettings.paymentIcons &&
                    paymentIconSettings.paymentIcons.split(",")
                        .map(iconId => availablePaymentIcons.find(s => s.id === iconId))
                        .filter(icon => icon !== undefined)
                        .map(icon => (
                            <div key={icon?.id} className={"inline-block w-fit " + getImgHeight()}>
                                <img
                                    className={"h-full rounded-md"}
                                    src={icon?.iconUrl}
                                    alt={`Payment method: ${icon?.name || icon?.id}`}
                                />
                            </div>
                        ))
                }
            </div>
        </div>
    )]

    if (isMobile) {
        return null;
    }

    return (
        <div className="w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto">
            <div className="mb-6">
                <Heading2 className={"mt-4"}>Forhåndsvisning av powerup</Heading2>
                <p className="text-gray-600 mb-6">
                    Slik vil produktsiden se ut når powerup er aktivert.
                </p>

            </div>

            <div className="relative">
                <ProductPageMetaPreview
                    injectComponentsOnLine7={paymentIconSettings?.placement ? paymentIconSettings?.placement == PaymentIconPlacement.ABOVE_PURCHASE_BUTTON ? injectionComponents : undefined : injectionComponents}
                    injectComponentsOnLine8={paymentIconSettings?.placement == PaymentIconPlacement.BELOW_PURCHASE_BUTTON ? injectionComponents : undefined}
                />
            </div>
        </div>
    );
};

export default PaymentIconsProductMetaPreview;