import React, {useEffect, useState} from "react";
import Heading2 from "../../components/text/Heading2";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import TextAreaField from "../../components/input-fields/TextAreaField";
import axios from "axios";
import {getBackendURL, getFrontendURL} from "../../utils/EnvironmentsManager";
import {useCurrentUser} from "../../providers/UserProvider";
import {toastError} from "../../utils/ErrorHandlerUtils";
import Spinner from "../../components/loading/Spinner";
import {motion} from "framer-motion";
import {CheckBadgeIcon} from "@heroicons/react/24/outline";
import {ReferralInvitation} from "../../types/ReferralInvitation";
import Heading3 from "../../components/text/Heading3";
import {formatDateIsoString} from "../../utils/FormatUtils";
import {ReferralStatus} from "../../enums/ReferralStatus";

const ReferralPage: React.FC = () => {
    const [recipentName, setRecipentName] = useState<string | undefined>(undefined)
    const [expectedReferralId, setExpectedReferralId] = useState<string>(generateUUID())
    const [invitationSent, setInvitationSent] = useState(false);
    const [invitations, setInvitations] = useState<ReferralInvitation[] | undefined>(undefined)

    const methods = useForm()
    const {currentUser} = useCurrentUser()

    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const onSubmit = (values: FieldValues) => {
        axios.post(getBackendURL() + "/api/v1/referral/create-invitation", {
            name: values["name"],
            email: values["email"],
            personalMessage: values["personalMessage"]
        }).then(result => {
            setInvitationSent(true)
        }).catch(error => {
            toastError(error)
        })
    }

    useEffect(() => {
        axios.get(getBackendURL() + "/api/v1/referral/list").then(result => {
            setInvitations(result.data)
        }).catch(error => {
            toastError(error)
        })
    }, []);

    const getStatusText = (referralStatus: ReferralStatus) => {
        switch (referralStatus) {
            case ReferralStatus.CREATED:
                return "Opprettet"
            case ReferralStatus.EXPIRED:
                return "Utgått"
            case ReferralStatus.FAILED:
                return "Feilet"
            case ReferralStatus.SENT:
                return "Invitasjon sendt"
            case ReferralStatus.REGISTERED:
                return "Bruker registrert"
            case ReferralStatus.STARTED_TRIAL:
                return "Vervet 🎉"
            default:
                return "Ukjent"
        }
    }

    return (
        <div className={"max-w-[900px] bg-white shadow-xl p-10 rounded-xl md:mx-auto"}>
            <div className={"flex flex-col gap-y-6"}>
                {invitationSent ? (
                    <div className={"w-full h-full flex flex-col justify-center items-center"}>
                        <motion.div
                            initial={{opacity: 0, rotateZ: 30}}
                            animate={{
                                opacity: 1,
                                rotateZ: 0,
                                transition: {delay: 0.6}
                            }}
                            transition={{duration: 0.3}}>

                            <CheckBadgeIcon className={"text-main-gradient-first size-20"}/>

                        </motion.div>
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{
                                opacity: 1,
                                transition: {delay: 1}
                            }}
                            transition={{duration: 0.3}}>

                            <h1 className={"text-xl mt-3"}>Invitasjon sendt</h1>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0}}
                            animate={{
                                opacity: 1,
                                transition: {delay: 1}
                            }}
                            transition={{duration: 0.3}}>

                            <MainButton className={"mt-8"} onClickEvent={() => window.location.reload()}>Den er
                                grei</MainButton>
                        </motion.div>
                    </div>
                ) : (
                    <>
                        <Heading2 withoutMargin={true}>Verv en venn</Heading2>

                        {(currentUser && expectedReferralId) ? (
                            <>
                                <p>Verv en venn, så får begge en måned gratis med Enthemed</p>
                                <p>Fyll ut skjemaet, så sender vi en invitasjon til mottakeren på dine vegne.</p>

                                <FormProvider {...methods}>
                                    <form className={"flex flex-col gap-y-6"} onSubmit={methods.handleSubmit(onSubmit)}>
                                        <InputTextField
                                            name={"name"}
                                            type={"text"}
                                            label={"Mottakerens navn"}
                                            onChange={setRecipentName}
                                            validation={{
                                                required: {value: true, message: "Mottakers navn er obligatorisk"},
                                                maxLength: {value: 100, message: "Mottakerens navn er for langt"},
                                                minLength: {
                                                    value: 2,
                                                    message: "Mottakerens navn må være minimum 2 bokstaver"
                                                }
                                            }}
                                        />

                                        <InputTextField
                                            name={"email"}
                                            type={"email"}
                                            label={"Mottakerens e-post"}
                                            validation={{
                                                required: {value: true, message: "Mottakers epost er obligatorisk"},
                                                maxLength: {value: 100, message: "Mottakerens epost er for langt"},
                                                minLength: {
                                                    value: 2,
                                                    message: "Mottakerens epost må være minimum 2 bokstaver"
                                                },
                                            }}
                                        />

                                        <TextAreaField
                                            name={"personalMessage"}
                                            label={"Personlig beskjed"}
                                            value={"Hei" + (recipentName ? " " + recipentName.split(" ")[0] : "") + ",\n" +
                                                "\n" +
                                                currentUser?.firstName + " " + currentUser?.lastName + " bruker Enthemed til nettbutikken sin, og tror det kunne passet for deg også.\n" +
                                                "\n" +
                                                "Velger du å bestille Enthemed i løpet av de neste tre månedene, får dere begge èn måned gratis.\n" +
                                                "\n" +
                                                "Prøv Enthemed gratis med denne linken:\n" +
                                                getFrontendURL() + "/register/" + expectedReferralId + "\n" +
                                                "\n" +
                                                "Mvh\n" +
                                                "Enthemed"}
                                            validation={{
                                                required: {value: false, message: "Mottakers epost er obligatorisk"},
                                                maxLength: {
                                                    value: 1000,
                                                    message: "Personlig beskjed kan maks være på 1000 tegn"
                                                },
                                                validate: (value) => {
                                                    const regex = /\/register\/[0-9a-fA-F-]{36}/;
                                                    return regex.test(value) || "Meldingen må inneholde registreringslenken din: " + getFrontendURL() + "/register/" + expectedReferralId;
                                                },
                                            }}
                                        />

                                        <div className={"grid text-center gap-y-4 sm:text-left sm:grid-cols-2"}>
                                            <div className={"text-sm text-gray-500"}>
                                                Du kan verve inntil 20 personer.
                                            </div>
                                            <div>
                                            <MainButton className={"mr-auto ml-auto sm:mr-0"}>Send
                                                    invitasjon</MainButton>
                                            </div>
                                        </div>
                                    </form>
                                </FormProvider>
                            </>
                        ) : (
                            <div className={"flex justify-center items-center min-h-80"}>
                                <Spinner/>
                            </div>
                        )}
                    </>
                )}

                {invitations && invitations.length > 0 && (
                    <div className={"mt-10"}>
                        <Heading3>Sendte invitasjoner</Heading3>
                        <table className={"w-full table-fixed border-spacing-4 border-[1px] border-gray-200 border-collapse mt-4"}>
                            <thead>
                            <tr>
                                <th className={"p-2 border border-gray-200"}>
                                    E-post
                                </th>
                                <th className={"p-2 border border-gray-200 hidden sm:table-cell"}>
                                    Dato sendt
                                </th>
                                <th>
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {invitations.map(invitation => (
                                <tr key={invitation.id} className={"h-14"}>
                                    <td className={"p-2 border border-gray-200 max-w-56 overflow-hidden"}>
                                        {invitation.recipientEmail}
                                    </td>
                                    <td className={"p-2 border border-gray-200 hidden sm:table-cell"}>
                                        {formatDateIsoString(invitation.sentAt)}
                                    </td>
                                    <td className={"p-2 border border-gray-200"}>
                                        {getStatusText(invitation.status)}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReferralPage