import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { usePowerUpsProvider } from "../../../providers/PowerUpsProvider";
import { Product } from "../../../types/Product";
import { getBackendURL } from "../../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import { toastError } from "../../../utils/ErrorHandlerUtils";
import DestructiveButton from "../../../components/buttons/DestructiveButton";
import MainButton from "../../../components/buttons/MainButton";
import Spinner from "../../../components/loading/Spinner";
import ProductSelection from "./ProductSelection";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import ShoppingCartUpsellPreview from "./ShoppingCartUpsellPreview";
import UpsellProductItem from "./UpsellProductItem";
import { UpsellProduct } from "../types/UpsellProduct";
import { OneTickUpsellRulePayload } from "../types/OneTickUpsellPayload";
import { OneTickUpsellRuleType } from "../types/OneTickUpsellRuleType";
import { OneTickUpsellRule } from "../types/OneTickUpsellRule";
import { PreviewItem } from "../types/PreviewItem";
import { Collection } from "../../../types/Collection";
import {PagedCollectionResponseDTO} from "../types/PagedCollectionResponse";
import {validateUpsellForm} from "../validation/ValidateUpsellForm";

export const ShoppingCartUpsellForm = () => {
    const { closeCustomizeMenu, disablePowerUp, setIsSendingData, isSendingData } = usePowerUpsProvider();
    const methods = useForm();

    // State
    // Eksisterende state
    const [isSaving, setIsSaving] = useState(false);
    const [productList, setProductList] = useState<Product[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [collectionList, setCollectionList] = useState<Collection[]>([]);
    const [selectedCollections, setSelectedCollections] = useState<Collection[]>([]);
    const [existingRule, setExistingRule] = useState<OneTickUpsellRule | null>(null);
    const [selectedRuleType, setSelectedRuleType] = useState<OneTickUpsellRuleType>(OneTickUpsellRuleType.ALL_PRODUCTS);
    const [isMobile, setIsMobile] = useState(false);


    // Preview items state
    const [previewItems, setPreviewItems] = useState<PreviewItem[]>([{
        id: '1',
        name: "Eksempelprodukt",
        price: 599.00,
        quantity: 1,
        image: ""
    }]);

    // Upsell products og preview states
    const [upsellProducts, setUpsellProducts] = useState<UpsellProduct[]>([{
        id: '1',
        upsellText: "🔥 Legg til {{tittel}} for {{pris}}",
        tickedByDefault: true,
        variant: 'switch',
        alignment: 'left',
        highlightColor: '#C21DE7',
        switchColor: '#6B7AFF',
        position: 1
    }]);

    // Separate preview states
    const [previewStates, setPreviewStates] = useState<{ [key: string]: boolean }>({});

    // Initialize preview states når upsell products endres
    useEffect(() => {
        const initialPreviewStates = Object.fromEntries(
            upsellProducts.map(up => [up.id, up.tickedByDefault])
        );
        setPreviewStates(initialPreviewStates);
    }, [upsellProducts]);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const initializeFromExistingRule = (rule: OneTickUpsellRule, products: Product[], collections: Collection[]) => {
        if (!rule) return;

        setSelectedRuleType(rule.ruleType);

        if (rule.ruleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS && rule.targetProducts) {
            setSelectedProducts(rule.targetProducts);
        }

        if (rule.ruleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS && rule.targetCollections) {
            setSelectedCollections(rule.targetCollections);
        }

        if (rule.upsellProducts?.length > 0) {
            const mappedUpsells: UpsellProduct[] = rule.upsellProducts
                .map((up, index) => {
                    const product = products.find(p =>
                        p.shopifyAdminAPIId === up.upsellProductId ||
                        p.graphqlId === up.upsellProductGraphqlId
                    );

                    return {
                        id: up.id,
                        product: product,
                        upsellText: up.upsellText,
                        tickedByDefault: up.tickedByDefault,
                        variant: up.variant || 'switch',
                        alignment: up.alignment || 'left',
                        highlightColor: up.highlightColor || '#C21DE7',
                        switchColor: up.switchColor || '#6B7AFF',
                        position: up.position ?? index
                    };
                })
                .sort((a, b) => a.position - b.position);

            setUpsellProducts(mappedUpsells);

            // Initialiser preview states fra eksisterende regel
            const initialPreviewStates = Object.fromEntries(
                mappedUpsells.map(up => [up.id, up.tickedByDefault])
            );
            setPreviewStates(initialPreviewStates);
        }
    };

    useEffect(() => {
        const initializeData = async () => {
            try {
                const [productsResponse, collectionsResponse] = await Promise.all([
                    axios.get<Product[]>(getBackendURL() + "/api/v1/product/list"),
                    axios.get<PagedCollectionResponseDTO>(getBackendURL() + "/api/v1/collection/list")
                ]);

                const products = productsResponse.data;
                const collections = collectionsResponse.data.collections;

                setProductList(products);
                setCollectionList(collections);

                if (products.length > 0) {
                    const ruleResponse = await axios.get<OneTickUpsellRule>(
                        getBackendURL() + "/api/v1/power-ups/one-tick-upsell/list"
                    );
                    setExistingRule(ruleResponse.data);
                    initializeFromExistingRule(ruleResponse.data, products, collections);
                }
            } catch (error) {
                toastError(error);
            }
        };

        initializeData();
    }, []);

    useEffect(() => {
        if (productList.length > 0) {
            const previewProduct = productList.find(p => p.variants?.length > 0);
            if (previewProduct?.variants[0]) {
                setPreviewItems([{
                    id: previewProduct.variants[0].id,
                    name: previewProduct.title,
                    price: previewProduct.variants[0].price,
                    quantity: 1,
                    image: "/api/placeholder/100/100"
                }]);
            }
        }
    }, [productList]);

    const addNewUpsellProduct = () => {
        const newProduct = {
            id: Date.now().toString(),
            upsellText: "🔥 Legg til {{tittel}} for {{pris}}",
            tickedByDefault: false,
            variant: 'switch' as const,
            alignment: 'left' as const,
            highlightColor: '#C21DE7',
            switchColor: '#6B7AFF',
            position: upsellProducts.length
        };

        setUpsellProducts(prevProducts => [...prevProducts, newProduct]);

        // Initialiser preview state for nytt produkt
        setPreviewStates(prev => ({
            ...prev,
            [newProduct.id]: newProduct.tickedByDefault
        }));
    };

    const removeUpsellProduct = (id: string) => {
        if (upsellProducts.length > 1) {
            setUpsellProducts(upsellProducts.filter(up => up.id !== id));

            // Fjern preview state for fjernet produkt
            setPreviewStates(prev => {
                const newState = { ...prev };
                delete newState[id];
                return newState;
            });
        }
    };

    const updateUpsellProduct = (id: string, updates: Partial<UpsellProduct>) => {
        setUpsellProducts(prevProducts => {
            const newProducts = prevProducts.map(up =>
                up.id === id ? { ...up, ...updates } : up
            );
            return newProducts.map((product, index) => ({
                ...product,
                position: index
            }));
        });

        // Oppdater preview state hvis tickedByDefault endres
        if ('tickedByDefault' in updates) {
            setPreviewStates(prev => ({
                ...prev,
                [id]: updates.tickedByDefault ?? prev[id]
            }));
        }
    };

    const handleSubmit = () => {
        const errors = validateUpsellForm({
            ruleType: selectedRuleType,
            selectedProducts,
            selectedCollections,
            upsellProducts
        });

        if (errors.length > 0) {
            toast.error(errors.join('\n'));
            return;
        }

        if (isSendingData) {
            return;
        }
        setIsSendingData(true);
        setIsSaving(true);

        const payload: OneTickUpsellRulePayload = {
            ruleType: selectedRuleType,
            targetProductGraphqlIds: selectedRuleType === OneTickUpsellRuleType.SPECIFIC_PRODUCTS
                ? selectedProducts.map(p => p.graphqlId)
                : [],
            targetCollectionGraphqlIds: selectedRuleType === OneTickUpsellRuleType.SPECIFIC_COLLECTIONS
                ? selectedCollections.map(c => c.graphqlId)
                : [],
            upsellProducts: upsellProducts.map((up, index) => ({
                upsellProductId: up.product?.shopifyAdminAPIId ?? 0,
                upsellProductGraphqlId: up.product?.graphqlId ?? '',
                upsellProductVariantId: up.product?.variants[0]?.shopifyAdminAPIId ?? 0,
                upsellProductHandle: up.product?.handle ?? '',
                upsellText: up.upsellText,
                tickedByDefault: up.tickedByDefault,
                variant: up.variant,
                alignment: up.alignment,
                highlightColor: up.highlightColor,
                switchColor: up.switchColor,
                position: up.position ?? index
            }))
        };

        axios.post<void>(`${getBackendURL()}/api/v1/power-ups/one-tick-upsell/add`, payload)
            .then(() => {
                toast.success("Lagret!");
                closeCustomizeMenu();
            })
            .catch((error) => {
                toast.error((error));
            })
            .finally(() => {
                setIsSendingData(false);
                setIsSaving(false);
            });
    };

    const disablePowerUpClicked = () => {
        if (isSendingData) return;
        setIsSendingData(true);
        disablePowerUp();
    };

    if (!productList.length) {
        return <Spinner className="flex justify-center" />;
    }

    return (
        <FormProvider {...methods}>
            <div className="flex flex-col">
                <div className={`grid gap-8 ${isMobile ? 'grid-cols-1' : 'grid-cols-12'}`}>
                    {/* Venstre kolonne - Customization Forms */}
                    <div className={`${isMobile ? 'col-span-1' : 'col-span-7'}`}>
                        <ProductSelection
                            productList={productList}
                            collectionList={collectionList}
                            onProductsSelected={setSelectedProducts}
                            onCollectionsSelected={setSelectedCollections}
                            selectedProducts={selectedProducts}
                            selectedCollections={selectedCollections}
                            selectedRuleType={selectedRuleType}
                            onRuleTypeChange={setSelectedRuleType}
                        />

                        {/* Upsell Products Section */}
                        <div className="mt-8 space-y-6">
                            {upsellProducts.map((upsellProduct, index) => (
                                <UpsellProductItem
                                    key={upsellProduct.id}
                                    upsellProduct={upsellProduct}
                                    index={index}
                                    productList={productList}
                                    onRemove={removeUpsellProduct}
                                    onUpdate={updateUpsellProduct}
                                    isOnly={upsellProducts.length === 1}
                                />
                            ))}

                            <SecondaryButton
                                onClickEvent={addNewUpsellProduct}
                                className="w-full"
                                disabled={upsellProducts.length >= 3}
                                disabledText="Maksimalt antall oppsalgsprodukter (3) er nådd"
                            >
                                + Legg til nytt oppsalgs produkt
                            </SecondaryButton>
                        </div>
                    </div>

                    {/* Høyre kolonne - Preview */}
                    <div className="col-span-5">
                        <div className="sticky top-6">
                            <div className="relative my-8">
                                <div className="absolute -left-4 top-0 bottom-0 border-l border-gray-200"/>
                                <div>
                                    {!productList.length ? (
                                        <div className="text-gray-600">Laster inn produkt...</div>
                                    ) : (
                                        <ShoppingCartUpsellPreview
                                            items={previewItems}
                                            upsellProducts={upsellProducts}
                                            previewStates={previewStates}
                                            onUpsellChange={(id: string, enabled: boolean) => {
                                                setPreviewStates(prev => ({
                                                    ...prev,
                                                    [id]: enabled
                                                }));
                                            }}
                                            getProductPrice={(product: Product) => {
                                                const variant = product.variants[0];
                                                return variant?.price || 0;
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Action buttons */}
                <div className="bg-white py-4 border-t mt-8">
                    <div className="flex justify-center gap-5 items-center">
                        <DestructiveButton
                            onClickEvent={disablePowerUpClicked}
                            disabled={isSendingData}
                        >
                            Deaktiver
                        </DestructiveButton>
                        <MainButton
                            onClickEvent={handleSubmit}
                            showProcessingAnimation={isSaving}
                            disabled={isSendingData || upsellProducts.some(up => !up.product)}
                        >
                            {existingRule ? 'Oppdater powerup' : 'Aktiver powerup'}
                        </MainButton>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default ShoppingCartUpsellForm;