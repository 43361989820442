// Separat komponent for upsell produkt item
import ShoppingCartUpsellTextSettings from "./ShoppingCartUpsellTextCustomization";
import ShoppingCartUpsellCustomization from "./ShoppingCartUpsellCustomization";
import CampaignProductSelector from "./ProductCampaignSelector";
import {Product} from "../../../types/Product";
import {UpsellProduct} from "../types/UpsellProduct";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import DestructiveButton from "../../../components/buttons/DestructiveButton";

interface UpsellProductItemProps {
    upsellProduct: UpsellProduct;
    index: number;
    productList: Product[];
    onRemove: (id: string) => void;
    onUpdate: (id: string, updates: Partial<UpsellProduct>) => void;
    isOnly: boolean;
}

const UpsellProductItem: React.FC<UpsellProductItemProps> = ({
                                                                 upsellProduct,
                                                                 index,
                                                                 productList,
                                                                 onRemove,
                                                                 onUpdate,
                                                                 isOnly
                                                             }) => {
    const isFirstProduct = index === 0;

    return (
        <div className="p-4 border rounded-lg bg-white shadow-sm">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                    Oppsalg produkt {index + 1}
                </h3>
                {!isFirstProduct && (
                    <DestructiveButton
                        onClickEvent={() => onRemove(upsellProduct.id)}
                        disabled={isFirstProduct}
                    >
                        Fjern
                    </DestructiveButton>
                )}
            </div>
            <div className="mt-4">
                <ShoppingCartUpsellCustomization
                    selectedVariant={upsellProduct.variant}
                    selectedAlignment={upsellProduct.alignment}
                    highlightColor={upsellProduct.highlightColor}
                    switchColor={upsellProduct.switchColor}
                    onVariantChange={(option) => {
                        if (option) onUpdate(upsellProduct.id,
                            { variant: option.id.toString() });
                    }}
                    onAlignmentChange={(option) => {
                        if (option) onUpdate(upsellProduct.id,
                            { alignment: option.id.toString() });
                    }}
                    onHighlightColorChange={(color) =>
                        onUpdate(upsellProduct.id, { highlightColor: color })}
                    onSwitchColorChange={(color) =>
                        onUpdate(upsellProduct.id, { switchColor: color })}
                />
            </div>

            <div className="mt-4">
                <ShoppingCartUpsellTextSettings
                    tickedByDefault={upsellProduct.tickedByDefault}
                    upsellText={upsellProduct.upsellText}
                    onTickedByDefaultChange={(value) =>
                        onUpdate(upsellProduct.id, { tickedByDefault: value })
                    }
                    onUpsellTextChange={(value) =>
                        onUpdate(upsellProduct.id, { upsellText: value })
                    }
                    productId={upsellProduct.id}
                />
            </div>
            <CampaignProductSelector
                productList={productList}
                onProductSelected={(product) =>
                    onUpdate(upsellProduct.id, { product })
                }
                selectedProduct={upsellProduct.product}
            />
        </div>
    );
};
export default UpsellProductItem