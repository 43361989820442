import React, { useState, useEffect } from 'react';
import { Product } from '../../../types/Product';
import ShoppingCartPreview, { CartAction } from "../../../components/preview/ShoppingCartPreview";
import { UpsellProduct } from "../types/UpsellProduct";
import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import {ToggleSwitch} from "../../../components/checkboxes/ToggleSwitch";
import {CircleRadio} from "../../../components/checkboxes/CircleRadio";
import {SquareCheckbox} from "../../../components/checkboxes/SquareCheckBox";

type ShoppingCartUpsellPreviewProps = {
    items: Array<any>;
    upsellProducts: UpsellProduct[];
    onUpsellChange: (id: string, enabled: boolean) => void;
    getProductPrice: (product: Product) => number;
    previewStates?: { [key: string]: boolean };
};

const ShoppingCartUpsellPreview = ({
                                       items,
                                       upsellProducts,
                                       onUpsellChange,
                                       getProductPrice,
                                       previewStates: externalPreviewStates,
                                   }: ShoppingCartUpsellPreviewProps) => {
    const [isMobile, setIsMobile] = useState(false);
    const [internalPreviewStates, setInternalPreviewStates] = useState<{ [key: string]: boolean }>({});
    const [total, setTotal] = useState(0);

    // Bruk enten eksterne preview states eller interne
    const previewStates = externalPreviewStates || internalPreviewStates;

    useEffect(() => {
        if (!externalPreviewStates) {
            const initialStates = Object.fromEntries(
                upsellProducts.map(up => [up.id, up.tickedByDefault])
            );
            setInternalPreviewStates(initialStates);
        }
    }, []);

    // Kalkuler total når items, upsellProducts eller previewStates endres
    useEffect(() => {
        const calculateTotal = () => {
            const itemsTotal = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
            const upsellTotal = upsellProducts.reduce((sum, upsell) => {
                if (previewStates[upsell.id] && upsell.product) {
                    return sum + getProductPrice(upsell.product);
                }
                return sum;
            }, 0);
            return itemsTotal + upsellTotal;
        };

        setTotal(calculateTotal());
    }, [items, upsellProducts, previewStates, getProductPrice]);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const formatPrice = (price: number | string): string => {
        const numericPrice = typeof price === 'string'
            ? parseFloat(price.replace(',', '.'))
            : price;

        return `${numericPrice.toLocaleString('nb-NO', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} kr`;
    };

    const formatUpsellText = (text: string, product?: Product, highlightColor?: string) => {
        const parts = text.split(/\{\{(tittel|pris)\}\}/);

        return parts.map((part, index) => {
            if (part === 'tittel') {
                const displayText = product?.title || '"Eksempel produkt"';
                return (
                    <span key={`title-${index}`} style={{ color: highlightColor }}>
                        {displayText}
                    </span>
                );
            }
            if (part === 'pris') {
                const price = product ? getProductPrice(product) : 0;
                return <span key={`price-${index}`}>{formatPrice(price)}</span>;
            }
            return <span key={`text-${index}`}>{part}</span>;
        });
    };

    const getUpsellStyle = (upsell: UpsellProduct) => {
        const alignmentClass = {
            'left': 'justify-start',
            'center': 'justify-center',
            'right': 'justify-end'
        }[upsell.alignment] || 'justify-start';

        return {
            container: `flex items-center gap-2 ${alignmentClass} text-sm md:text-base`,
            switch: upsell.switchColor,
        };
    };

    const renderToggle = (upsell: UpsellProduct, isChecked: boolean, onChange: () => void) => {
        switch (upsell.variant) {
            case 'square':
                return (
                    <SquareCheckbox
                        name={`upsell-${upsell.id}`}
                        isCheckedByDefault={isChecked}
                        onClickEvent={onChange}
                        switchColor={upsell.switchColor}
                    />
                );

            case 'circle':
                return (
                    <CircleRadio
                        name={`upsell-${upsell.id}`}
                        isCheckedByDefault={isChecked}
                        onClickEvent={onChange}
                        switchColor={upsell.switchColor}
                    />
                );

            case 'switch':
            default:
                return (
                    <ToggleSwitch
                        name={`upsell-${upsell.id}`}
                        isCheckedByDefault={isChecked}
                        onClickEvent={onChange}
                        switchColor={upsell.switchColor}
                    />
                );
        }
    };

    const handleSwitchChange = (id: string, checked: boolean) => {
        if (!externalPreviewStates) {
            setInternalPreviewStates(prev => ({
                ...prev,
                [id]: checked
            }));
        }
        onUpsellChange(id, checked);
    };

    const upsellActions: CartAction[] = upsellProducts
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
        .map(upsell => ({
            id: upsell.id,
            position: 'before-total',
            component: (
                <div key={upsell.id} className={getUpsellStyle(upsell).container}>
                    {renderToggle(
                        upsell,
                        previewStates[upsell.id] ?? false,
                        () => handleSwitchChange(upsell.id, !previewStates[upsell.id])
                    )}
                    <span className="text-sm md:text-base">
                        {formatUpsellText(upsell.upsellText, upsell.product, upsell.highlightColor)}
                    </span>
                </div>
            )
        }));

    if (isMobile) {
        return null;
    }

    return (
        <div className="w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto">
            <div className="mb-6">
                <Heading2 className={"mt-4"}>Forhåndsvisning av powerup</Heading2>
                <p className="text-gray-600 mb-6">
                    Slik vil handlekurven se ut når powerup er aktivert.
                </p>

            </div>

            <div className="relative">
                <ShoppingCartPreview
                    items={items}
                    actions={upsellActions}
                    readonly
                    totalOverride={total}
                />
            </div>
        </div>
    );
};

export default ShoppingCartUpsellPreview;