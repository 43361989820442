import React, { useState, useCallback, useMemo } from 'react';
import { Product } from '../../../types/Product';
import CheckBoxComponent from '../../../components/checkboxes/CheckBoxComponent';
import Callout from "../../../components/text/Callout";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import MainButton from "../../../components/buttons/MainButton";

interface CampaignProductSelectorProps {
    productList: Product[];
    onProductSelected: (product: Product) => void;
    selectedProduct?: Product;
}

const CampaignProductSelector: React.FC<CampaignProductSelectorProps> = ({
                                                                             productList,
                                                                             onProductSelected,
                                                                             selectedProduct,
                                                                         }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showSelector, setShowSelector] = useState(!selectedProduct);
    const [showContent, setShowContent] = useState(false);

    // Memoize filtered products to prevent unnecessary recalculations
    const filteredProducts = useMemo(() =>
            productList.filter(product =>
                product.title.toLowerCase().includes(searchTerm.toLowerCase())
            ),
        [productList, searchTerm]
    );

    // Extract handler logic to avoid duplication
    const handleProductSelect = useCallback((product: Product) => {
        onProductSelected(product);
        setShowSelector(false);
    }, [onProductSelected]);

    // Separate handler for search input
    const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    }, []);

    // Handle content toggle
    const toggleContent = useCallback(() => {
        setShowContent(prev => !prev);
    }, []);

    return (
        <div className="space-y-6">
            <div>
                <h2 className="text-2xl font-semibold mb-2 mt-6">Oppsalgprodukt</h2>
                <p className="text-gray-600">
                    Velg produktet du ønsker at skal legges til i handlekurven.
                </p>
                <MainButton
                    className="mt-4"
                    onClickEvent={toggleContent}
                    aria-expanded={showContent}
                >
                    {showContent ? 'Skjul innhold' : 'Velg produkt'}
                </MainButton>
            </div>

            {showContent && (
                <>
                    {!showSelector && selectedProduct ? (
                        <>
                            <div className="bg-white rounded-lg shadow-sm border p-4 flex items-center justify-between">
                                <div className="flex items-center gap-4">
                                    <span className="font-medium">{selectedProduct.title}</span>
                                </div>
                                <button
                                    onClick={() => setShowSelector(true)}
                                    className="text-red-500 hover:text-red-600"
                                    aria-label="Fjern valgt produkt"
                                >
                                    ✕
                                </button>
                            </div>

                            <Callout className="mb-10">
                                <div className="flex flex-row items-center">
                                    <span className="flex gap-4">
                                        <LightBulbIcon className="w-6 text-hover-color" aria-hidden="true" />
                                        <span>
                                            Hvis et produkt er i handlekurven, kan kunden legge til "{selectedProduct.title}" med ett klikk.
                                        </span>
                                    </span>
                                </div>
                            </Callout>
                        </>
                    ) : (
                        <div
                            className="rounded-lg border border-gray-200 overflow-hidden"
                            role="region"
                            aria-label="Produktvelger"
                        >
                            <input
                                type="text"
                                placeholder="Søk etter produkter..."
                                className="w-full p-4 border-b border-gray-200"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                aria-label="Søk etter produkter"
                            />

                            <div
                                className="max-h-96 overflow-y-auto divide-y divide-gray-200"
                                role="listbox"
                            >
                                {filteredProducts.map(product => (
                                    <div
                                        key={product.id}
                                        className="flex items-center gap-3 p-4 hover:bg-gray-50"
                                        role="option"
                                        aria-selected={selectedProduct?.id === product.id}
                                    >
                                        <CheckBoxComponent
                                            onClickEvent={() => handleProductSelect(product)}
                                            name={`campaign-product-${product.id}`}
                                            isCheckedByDefault={selectedProduct?.id === product.id}
                                            aria-label={`Velg ${product.title}`}
                                        />
                                        <span className="flex-1">{product.title}</span>
                                    </div>
                                ))}

                                {filteredProducts.length === 0 && (
                                    <div className="p-4 text-gray-500 text-center">
                                        Ingen produkter funnet
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CampaignProductSelector;