import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import ErrorText from "../input-fields/ErrorText";

interface CircleRadioProps {
    name: string;
    options: { label: string; value: string }[];
    defaultValue?: string;
    className?: string;
    switchColor?: string;
    validation?: any;
    onChange?: (value: string) => void;
}

export const CircleRadio: React.FC<CircleRadioProps> = ({
                                                            name,
                                                            options,
                                                            defaultValue,
                                                            className,
                                                            switchColor = "#4F46E5",
                                                            validation,
                                                            onChange,
                                                        }) => {
    const {register, setValue, formState: {errors}} = useFormContext();
    const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue);

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
            setSelectedValue(defaultValue);
        }
    }, [defaultValue, name, setValue]);

    const handleChange = (value: string) => {
        setSelectedValue(value);
        setValue(name, value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div role="radiogroup" aria-labelledby={`${name}-group-label`} className={`flex gap-4 ${className}`}>
            {options.map(({label, value}) => (
                <label key={value} className="inline-flex items-center cursor-pointer" tabIndex={0} onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        handleChange(value);
                        e.preventDefault();
                    }
                }}>
                    <input
                        type="radio"
                        className="hidden"
                        {...register(name, validation)}
                        value={value}
                        checked={selectedValue === value}
                        onChange={() => handleChange(value)}
                    />
                    <div
                        className="w-5 h-5 border-2 rounded-full flex items-center justify-center"
                        style={{
                            borderColor: switchColor,
                            backgroundColor: selectedValue === value ? switchColor : "white",
                        }}
                    >
                        {selectedValue === value && <div className="w-2.5 h-2.5 rounded-full bg-white"/>}
                    </div>
                    <span className="ml-2">{label}</span>
                </label>
            ))}
            <ErrorText errors={errors} name={name}/>
        </div>
    );
};
