import MainButton from "../../components/buttons/MainButton";
import {toast} from "react-hot-toast";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";
import {useCurrentUser} from "../../providers/UserProvider";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import InputTextField from "../../components/input-fields/InputTextField";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import Heading2 from "../../components/text/Heading2";
import Callout from "../../components/text/Callout";
import Heading3 from "../../components/text/Heading3";
import {AdminLogRecord} from "../../types/AdminLogRecord";
import {formatDateIsoString} from "../../utils/FormatUtils";
import {TableBody, TableHead} from "@mui/material";

const AdminPage = () => {
    const methods = useForm()
    const navigate = useNavigate()
    const {currentUser} = useCurrentUser()
    const [loading, setLoading] = useState(false)

    const [adminLog, setAdminLog] = useState<AdminLogRecord[] | undefined>()

    useEffect(() => {
        if (!currentUser?.roles.some(role => role.name === "ROLE_ADMIN")) {
            navigate("/")
        }
    }, [currentUser]);

    const onSubmit = (values: FieldValues) => {
        setLoading(true)
        axios.post(getBackendURL() + "/api/v1/admin/login-as-user/" + values["email"]).then(result => {
            toast.success(result.data)
            window.location.reload();
        }).catch(error => {
            if (error.status === 404) {
                toast.error("Fant ikke bruker med epost: " + values["email"])
            } else {
                toastError(error)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        axios.get(getBackendURL() + "/api/v1/admin/logs").then(result => {
            setAdminLog(result.data.content)
        }).catch(error => {
            console.log(error)
        })
    }, []);

    return (
        <>
            <div className="container mx-auto px-10 py-5">
                <Heading2 className="text-left text-4xl mt-3">Admin</Heading2>
                <p className="text-gray-500 text-left text-md mt-2">
                    Denne siden er kun tilgjengelig for deg med Admin bruker.
                </p>
                <div className="border-b border-gray-200 my-5 mb-10"></div>
                <div className={"flex flex-col items-center gap-y-4 mb-12"}>
                    <Heading3>Husk at</Heading3>
                    <Callout className={"w-full sm:w-96"}>
                        With great power comes great responsibility!
                    </Callout>
                </div>

                <div className="border-b border-gray-200 my-5 mb-10"></div>

                <div className={"flex flex-row flex-wrap"}>
                    <div className={"flex flex-col gap-y-7 px-4 md:basis-1/4"}>
                        <div>
                            <Heading3>Logg inn som en annen bruker</Heading3>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)} className={"flex flex-col gap-y-5 mt-5"}>
                                    <InputTextField name={"email"} type={"email"} label={"Epost"}/>
                                    <MainButton
                                        showProcessingAnimation={loading}
                                    >
                                        Logg inn
                                    </MainButton>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-y-4 px-4 md:basis-3/4"}>
                        <Heading3>Admin logg:</Heading3>
                        <table>
                            <TableHead className={"text-left"}>
                                <th>
                                    Bruker
                                </th>
                                <th>
                                    Tekst
                                </th>
                                <th>
                                    Dato
                                </th>
                            </TableHead>
                            <TableBody>
                                {adminLog && adminLog?.map(record => (
                                    <tr key={record.id} className={"border-gray-300 border-t-[1px]"}>
                                        <td className={"p-2"}>{record.responsibleUserName}</td>
                                        <td className={"p-2"}>{record.text}</td>
                                        <td className={"p-2"}>{formatDateIsoString(record.timestamp)}</td>
                                    </tr>
                                ))}
                            </TableBody>
                        </table>
                    </div>
                    <div className={"flex flex-col items-center justify-center"}>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminPage