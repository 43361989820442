import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import { FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import ErrorText from "./ErrorText";

interface TextAreaFieldProps {
    name: string;
    label: string;
    value?: string | null;
    required?: boolean;
    style?: React.CSSProperties;
    validation?: RegisterOptions<FieldValues, string>;
    onChange?: (value: string) => void;
    min?: number;
}

const TextAreaField = (props: TextAreaFieldProps) => {
    const { register, setValue: setFormValue, formState: { errors } } = useFormContext();
    const [fieldValue, setFieldValue] = useState<string | undefined>(
        props.value
            ? props.min
                ? String(Math.max(Number(props.value), props.min))
                : props.value
            : undefined
    );

    // Type-safe check for required validation
    const isRequired = props.validation?.required && (
        typeof props.validation.required === 'boolean'
            ? props.validation.required
            : typeof props.validation.required === 'object'
                ? props.validation.required.value === true
                : false
    );

    useEffect(() => {
        setFieldValue(props.value ?? undefined);
    }, [props.value]);

    useEffect(() => {
        setFormValue(props.name, fieldValue);
    }, [fieldValue, props.name, setFormValue]);

    const { onChange, ...rest } = register(props.name, props.validation);

    return (
        <>
            <TextField
                spellCheck="true"
                style={{ marginTop: 20, ...props.style }}
                error={!!errors[props.name]}
                label={isRequired ? `${props.label}*` : props.label}
                value={fieldValue}
                multiline
                rows={4}
                required={props.required}
                onChange={(e) => {
                    let newValue = e.target.value;
                    if (props.min && Number(newValue) < props.min) {
                        newValue = String(props.min);
                    }
                    setFieldValue(newValue);
                    props.onChange?.(newValue);
                    onChange(e);
                }}
                {...rest}
            />
            <ErrorText errors={errors} name={props.name} />
        </>
    );
};

export default TextAreaField;