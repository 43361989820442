import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home-page/HomePage";
import {Toaster} from "react-hot-toast";
import {LoginProvider} from "./providers/LoginProvider";
import SignInPage from "./pages/authentication/SignInPage";
import SignUpPage from "./pages/authentication/SignUpPage";
import ForgotPasswordPage from "./pages/authentication/ForgotPasswordPage";
import ChangePasswordPage from "./pages/authentication/ChangePasswordPage";
import axios from "axios";
import {UserProvider} from "./providers/UserProvider";
import OnboardingWrapper from "./layout/OnboardingWrapper";
import {RequiresLicense} from "./providers/LicenseProvider";
import PageWrapper from "./PageWrapper";
import ThemePage from "./pages/themes-page/ThemePage";
import PowerUpsPage from "./pages/power-ups/PowerUpsPage";
import PrivacyPolicy from "./pages/legal-pages/PrivacyPolicy";
import TermsOfService from "./pages/legal-pages/TermsOfService";
import DeleteMeGuidePage from "./pages/legal-pages/DeleteMeGuidePage";
import GeneralSettingsPage from "./pages/settings/GeneralSettingsPage";
import AddStorePage from "./pages/add-store/AddStorePage";
import posthog from "posthog-js";
import IntegrateStorePage from "./pages/intergrate-store/IntegrateStorePage";
import UpdateThemePage from "./pages/update-theme/UpdateThemePage";
import AdminPage from "./pages/admin/AdminPage";
import ReferralPage from "./pages/referral-page/ReferralPage";

function App() {
    axios.defaults.withCredentials = true
    const [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect(() => {
        posthog.init('phc_c7Smcanw6Ei7WLhy84PF0PUS0DuZjVaq3ImHdpmqU1C',
            {
                api_host: 'https://eu.i.posthog.com',
                person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
                loaded: (posthog) => {
                    if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
                },
            }
        )
    }, []);

    return (
        <>
            <Toaster position={"top-center"}/>
            <BrowserRouter>
                <LoginProvider>
                    <Routes>
                        <Route path={"/login"} element={<SignInPage/>}/>
                        <Route path={"/register"} element={<SignUpPage/>}/>
                        <Route path={"/register/suksessklubb"} element={<SignUpPage/>}></Route>
                        <Route path={"/register/:uuid"} element={<SignUpPage/>}/>
                        <Route path={"/forgot-password"} element={<ForgotPasswordPage/>}/>
                        <Route path={"/change-password"} element={<ChangePasswordPage/>}/>
                        <Route path={"/delete-me"} element={<DeleteMeGuidePage/>}/>
                    </Routes>
                </LoginProvider>
                <Routes>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    <Route path={"/terms-of-service"} element={<TermsOfService/>}/>
                </Routes>
                <UserProvider>
                    <OnboardingWrapper>
                        <RequiresLicense>
                            <PageWrapper sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
                                <Routes>
                                    <Route path={"/"} element={<HomePage/>}/>
                                    <Route path={"/themes"} element={<ThemePage/>}/>
                                    <Route path={"/power-ups"} element={<PowerUpsPage/>}/>
                                    <Route path={"/referral"} element={<ReferralPage/>}/>
                                    <Route path={"/settings"} element={<GeneralSettingsPage/>}/>
                                    <Route path={"/add-store"} element={<AddStorePage/>}/>
                                    <Route path={"/integrate-store"} element={<IntegrateStorePage/>}/>
                                    <Route path={"/update-theme"} element={<UpdateThemePage/>}/>
                                    <Route path={"/admin"} element={<AdminPage />} />
                                </Routes>
                            </PageWrapper>
                        </RequiresLicense>
                    </OnboardingWrapper>
                </UserProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
