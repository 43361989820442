import {BaseToggleProps} from "../../power-ups/shopping-cart-upsell/types/ToggleProps";
import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import ErrorText from "../input-fields/ErrorText";

export const CircleRadio: React.FC<BaseToggleProps> = ({
                                                           name,
                                                           onClickEvent,
                                                           isCheckedByDefault,
                                                           className,
                                                           validation,
                                                           switchColor = '#4F46E5'
                                                       }) => {
    const { register, formState: { errors } } = useFormContext();
    const [checked, setChecked] = useState<boolean>(false);
    const { ref, onChange, ...rest } = register(name, validation);

    useEffect(() => {
        setChecked(isCheckedByDefault ?? false);
    }, [isCheckedByDefault]);

    const handleChange = () => {
        const event = { target: { name, value: !checked } } as unknown as React.ChangeEvent<HTMLInputElement>;
        onChange(event);
        setChecked(!checked);
        if (onClickEvent) {
            onClickEvent();
        }
    };

    return (
        <div
            className="inline-flex items-center justify-center cursor-pointer"
            onClick={handleChange}
        >
            <div
                className="w-5 h-5 border-2 rounded-full relative flex items-center justify-center"
                style={{
                    borderColor: switchColor,
                    backgroundColor: checked ? switchColor : 'white'
                }}
            >
                {checked && (
                    <div className="w-2.5 h-2.5 rounded-full bg-white" />
                )}
            </div>
            <input
                type="radio"
                className="hidden"
                ref={ref}
                checked={checked}
                onChange={() => {}}
                {...rest}
            />
            <ErrorText errors={errors} name={name} />
        </div>
    );
};
