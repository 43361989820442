import React, { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import {
    Container,
    Swatch,
    ColorDisplay,
    Popover,
    Cover,
    PickerContainer,
    Label
} from '../../utils/ColorPicker.styles';

interface ColorPickerProps {
    color: string;
    onChange: (color: string) => void;
    label: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange, label }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleChange = (colorResult: ColorResult) => {
        onChange?.(colorResult.hex);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    return (
        <Container>
            <Swatch onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                <ColorDisplay $color={color} />
            </Swatch>
            <Label>{label}</Label>
            {displayColorPicker && (
                <Popover>
                    <Cover onClick={handleClose} />
                    <PickerContainer>
                        <SketchPicker
                            color={color}
                            onChangeComplete={handleChange}
                            disableAlpha={false}
                            aria-label={`Color picker for ${label}`}
                            presetColors={[
                                '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321',
                                '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2',
                                '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'
                            ]}
                        />
                    </PickerContainer>
                </Popover>
            )}
        </Container>
    );
};

export default ColorPicker;