export const formatDateIsoString = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("nb-NO", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
};

export const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === 'string'
        ? parseFloat(price.replace(',', '.'))
        : price;

    return `${numericPrice.toLocaleString('nb-NO', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} kr`;
};