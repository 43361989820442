import React, { useState } from 'react';
import DropdownMenu, { MenuOption } from '../../../components/input-fields/DropdownMenu';
import { Switch } from "@mui/material";
import ColorPicker from "../../../components/input-fields/ColorPicker";

interface ShoppingCartUpsellCustomizationProps {
    onVariantChange: (option?: MenuOption) => void;
    onAlignmentChange: (option?: MenuOption) => void;
    onHighlightColorChange: (color: string) => void;
    onSwitchColorChange: (color: string) => void;
    selectedVariant?: string;
    selectedAlignment?: string;
    highlightColor: string;
    switchColor: string;
}

const ShoppingCartUpsellCustomization: React.FC<ShoppingCartUpsellCustomizationProps> = ({
                                                                                             onVariantChange,
                                                                                             onAlignmentChange,
                                                                                             onHighlightColorChange,
                                                                                             onSwitchColorChange,
                                                                                             selectedVariant,
                                                                                             selectedAlignment,
                                                                                             highlightColor,
                                                                                             switchColor
                                                                                         }) => {
    const variantOptions: MenuOption[] = [
        { id: 'switch', title: 'Bryter' },
        { id: 'square', title: 'Firkant' },
        { id: 'circle', title: 'Sirkel' }
    ];

    const alignmentOptions: MenuOption[] = [
        { id: 'center', title: 'Sentrert' },
        { id: 'left', title: 'Venstre' }
    ];

    return (
        <div className="space-y-6">
            <div>
                <h2 className="text-2xl font-semibold mb-2">Tilpass oppsettet</h2>
                <p className="text-gray-600">
                    Velg hvordan tilleggsprodukter skal vises i handlekurven.
                </p>
            </div>

            <div className="space-y-6">
                <div className="space-y-4">
                    <h3 className="text-lg font-medium">Farge tilpassning</h3>
                    <div className="space-y-4 grid grid-cols-2">
                        <ColorPicker
                            color={highlightColor}
                            onChange={onHighlightColorChange}
                            label="Produktnavn tekst"
                        />

                        <ColorPicker
                            color={switchColor}
                            onChange={onSwitchColorChange}
                            label="Figur farge"
                        />
                    </div>
                </div>

                <div className="grid gap-6 sm:grid-cols-2">
                    <DropdownMenu
                        name="variant"
                        label="Velg variant"
                        options={variantOptions}
                        defaultValue={selectedVariant}
                        onChange={onVariantChange}
                        hideEmpty={true}
                        validation={{
                            required: { value: true, message: "Variant er påkrevd" }
                        }}
                    />

                    <DropdownMenu
                        name="alignment"
                        label="Plassering i handlekurv"
                        options={alignmentOptions}
                        defaultValue={selectedAlignment}
                        onChange={onAlignmentChange}
                        hideEmpty={true}
                        validation={{
                            required: { value: true, message: "Justering er påkrevd" }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartUpsellCustomization;