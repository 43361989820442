// PowerUpMapper.tsx
import { PowerUpDTO } from "../../types/PowerUpDTO";
import SuggestedProductsForm from "../../power-ups/suggested-product/SuggestedProductsForm";
import DeliveryDateForm from "../../power-ups/delivery-date/components/DeliveryDateForm";
import PreOrderForm from "../../power-ups/pre-order/components/PreOrderForm";
import ShoppingCartUpsellForm from "../../power-ups/shopping-cart-upsell/components/ShoppingCartUpsellForm";
import InactiveTabForm from "../../power-ups/inactive-tab-warning/InactiveTabForm";
import CookieBannerForm from "../../power-ups/cookie-banner/CookieBannerForm";
import {PowerUpsCustomizeInfo} from "./types/PowerUpsCustomizeInfo";
import PowerUpPreviewContent from "./PowerUpPreviewContent";
import PaymentIconsForm from "../../power-ups/payment-icons/PaymentIconsForm";

export const powerUpCanBeCustomized = (powerUpDTO: PowerUpDTO) => {
    let fields = getSettingsForPowerUp(powerUpDTO.id)
    return fields != null
}

export const getPowerUpCustomizeInfoForPowerUp = (powerUpDTO: PowerUpDTO) => {
    let fields = getSettingsForPowerUp(powerUpDTO.id)
    if (fields == null) {
        return null
    }

    let object: PowerUpsCustomizeInfo = {
        fields: fields,
        powerUpDTO: powerUpDTO
    }
    return object
}

/**************************************************************************************/
/******************* THIS PART MUST BE CUSTOMIZED FOR EACH POWER-UP *******************/
/**************************************************************************************/

const getSettingsForPowerUp = (powerUpId: string) => {
    switch (powerUpId) {
        case "suggestedProduct": return <SuggestedProductsForm/>;
        case "deliveryDate": return <DeliveryDateForm/>;
        case "preOrder": return <PreOrderForm/>;
        case "shoppingCartUpsell": return <ShoppingCartUpsellForm/>;
        case "inactiveTabWarning": return <InactiveTabForm />
        case "cookieBanner": return <CookieBannerForm />
        case "paymentIcons": return <PaymentIconsForm/>;
        default: return null;
    }
}

export const getPreviewForPowerUp = (powerUp: PowerUpDTO) => {
    return <PowerUpPreviewContent powerUp={powerUp} />;
}