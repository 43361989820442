import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import ErrorText from "../input-fields/ErrorText";

interface ToggleSwitchProps {
    name: string;
    defaultValue?: boolean;
    className?: string;
    switchColor?: string;
    validation?: any;
    onChange?: (value: boolean) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
                                                              name,
                                                              defaultValue = false,
                                                              className,
                                                              switchColor = "#4F46E5",
                                                              validation,
                                                              onChange,
                                                          }) => {
    const {register, setValue, formState: {errors}} = useFormContext();
    const [checked, setChecked] = useState<boolean>(defaultValue);

    useEffect(() => {
        setValue(name, defaultValue);
        setChecked(defaultValue);
    }, [defaultValue, name, setValue]);

    const handleChange = () => {
        const newValue = !checked;
        setChecked(newValue);
        setValue(name, newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div className={`relative inline-block w-8 md:w-10 h-5 md:h-6 ${className}`}>
            <input
                type="checkbox"
                className="hidden"
                {...register(name, validation)}
                checked={checked}
                onChange={() => {
                }}
            />
            <div
                role="switch"
                aria-checked={checked}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        handleChange();
                        e.preventDefault();
                    }
                }}
                className="w-8 md:w-10 h-5 md:h-6 rounded-full p-1 cursor-pointer transition-colors duration-200 ease-in-out"
                style={{backgroundColor: checked ? switchColor : "#e5e7eb"}}
                onClick={handleChange}
            >
                <div
                    className={`bg-white w-3 md:w-4 h-3 md:h-4 rounded-full shadow-md transform duration-200 ease-in-out ${
                        checked ? "translate-x-3 md:translate-x-4" : ""
                    }`}
                />
            </div>
            <ErrorText errors={errors} name={name}/>
        </div>
    );
};
